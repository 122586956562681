import React from "react";
import LayoutWeek from "../../../components/Chronology/LayoutWeek";
import ModGraphFull from "../../../components/Chronology/Modules/ModGraphFull";

import Day71 from "./dia-23-may";
import Day72 from "./dia-24-may";
import Day73 from "./dia-25-may";
import Day74 from "./dia-26-may";
import Day75 from "./dia-27-may";
import Day76 from "./dia-28-may";
import Day77 from "./dia-29-may";

const Week = (props) => {
  return (
    <LayoutWeek {...props}>
      <Day77 {...props} />
      <Day76 {...props} />
      <Day75 {...props} />
      <Day74 {...props} />
      <ModGraphFull
        name="mapa-fases-transicion-2505"
        alt="mapa transicion fases españa"
      />
      <Day73 {...props} />
      <Day72 {...props} />
      <Day71 {...props} />
    </LayoutWeek>
  );
};

export default Week;
