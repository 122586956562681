import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModText from "../../../components/Chronology/Modules/ModText";
import InlineLink from "../../../components/Chronology/Modules/InlineLink";
import ModDatafactCountry from "../../../components/Chronology/Modules/ModDatafactCountry";
import ModDailyHighlight from "../../../components/Chronology/Modules/ModDailyHighlight";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";
import ModGraph from "../../../components/Chronology/Modules/ModGraph";

export const frontmatter = {
  title: "Día 73",
  week: "Semana 11",
  day: "25",
  month: "May",
  monthNumber: "05",
  date: "2020-05-25",
  path: "/cronologia/semana-11#dia-25-may/",
};

const Day73 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDottedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
        <ModDatafactCountry
          fecha={frontmatter.date}
          country="IT"
          data="casosConfirmados"
        />
      </ContentLeft>
      <ContentRight>
        <ModText>
          En <strong>España</strong>, el balance del día se salda con el
          registro de 455 nuevos positivos y 52 personas fallecidas. Permanecen
          hospitalizadas 124.521 personas, 95 menos que el día anterior. El
          número de casos ingresados en la UCI asciende a 11.454, lo que supone
          un aumento de 9 personas.
        </ModText>
        <ModText>
          El pasado{" "}
          <InlineLink link="https://coronavirus.secuoyas.com/semana-09#dia-11-may">
            11 de mayo
          </InlineLink>{" "}
          el Ministerio de Sanidad hizo publica una orden en la que establecía
          las{" "}
          <strong>
            obligaciones y procedimientos de obtención y comunicación de
            información para la vigilancia epidemiológica
          </strong>
          relacionada con COVID-19.
        </ModText>
        <ModText>
          Desde entonces, el CCAES ha detectado algunas desviaciones y
          desajustes entre las distintas CC.AA. Entre ellas, la consideración de
          casos fallecidos por coronavirus cuando éstos aún sólo eran una
          sospecha, y no habían sido confirmados como tal. Los datos han sido
          ajustados hoy al Sistema de Vigilancia Epidemiológica (SiViEs).
        </ModText>
        <ModDailyHighlight>
          El ajuste en los datos que está en proceso provoca ruptura en las
          series, que quedan reflejadas en nuestos módulos infomativos.
        </ModDailyHighlight>

        <ModText>
          El CDTI, entidad pública adscrita al Ministerio de Ciencia e
          Innovación, ha aprobado{" "}
          <strong>10 nuevos proyectos tecnológicos</strong> frente a COVID-19
          con una aportación de 5 millones de euros.
        </ModText>
        <ModGraph
          name="laboratorio-tecnologia"
          alt="laboratorio de investigación tecnológica"
        />

        <ModText>
          En el desarrollo de estos proyectos participan 10 empresas, de las
          cuales <strong>9 son PYMES</strong>, ubicadas en las Comunidades
          Autónomas de Cataluña, Madrid, Comunidad Valenciana, Aragón y en la
          Región de Murcia.
        </ModText>
        <ModText>
          Entre las temáticas abordadas por los proyectos aprobados, cabe
          resaltar las relacionadas con Kits de diagnóstico; desarrollo de
          luminarias Led para la desinfección de virus y bacterias; sistemas
          inteligentes biocidas y nuevos textiles con acción bacteriana y
          virucida frente al coronavirus, entre otras.
        </ModText>
        <ModText>
          La <strong>DGT</strong> ha{" "}
          <strong>reanudado los exámenes de los permisos de conducir</strong> en
          los territorios que se encuentren en Fase 2 de la desescalada.
        </ModText>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day73;
